var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', {
    staticClass: "base-transport-schedule-card",
    scopedSlots: _vm._u([_vm.daysTillTransport >= 0 ? {
      key: "title",
      fn: function () {
        return [_vm._v(" Transport " + _vm._s(_vm.$tc('base.transport.details.field.transportIn', _vm.daysTillTransport)) + " ")];
      },
      proxy: true
    } : _vm.daysTillDelivery >= 0 ? {
      key: "title",
      fn: function () {
        return [_vm._v(" Dostawa " + _vm._s(_vm.$tc('base.transport.details.field.transportIn', _vm.daysTillDelivery)) + " ")];
      },
      proxy: true
    } : {
      key: "title",
      fn: function () {
        return [_vm._v(" Transport zakończony ")];
      },
      proxy: true
    }], null, true)
  }, [_vm.isLoading ? _c('vs-loader', {
    attrs: {
      "full": "",
      "text": "Pobieranie danych zlecenia..."
    }
  }) : _vm._e(), _c('details-table', {
    attrs: {
      "entries": _vm.entries,
      "i18n-group": "base.transport.details.field"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }