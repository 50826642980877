<template>
  <widget-card
    title="Oś czasu"
    class="base-transport-schedule-card"
  >

    <div class="transport-timeline-wrapper">
      <timeline
        :items="events"
        hideHeader
      />
    </div>

    <vs-loader
      full
      text="Pobieranie danych zlecenia..."
      v-if="isLoading"
    />

  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import Timeline from '@/components/ui/timeline/Timeline.vue';
import useBaseApi from '@/hooks/base/useBaseApi';
import { onUnmounted, ref } from 'vue';
import useBaseModals from '@/hooks/base/useBaseModals';

export default {
  name: 'MachineDetailsCard',
  props: {
    transport: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const events = ref(null);

    const fetchLogs = () => {
      useBaseApi()
        .fetchTransportLogs(props.transport.id)
        .then(({ data }) => {
          events.value = (data.data ?? [])?.reverse();
        });
    };
    fetchLogs();

    const { modalEditTransport } = useBaseModals();
    modalEditTransport.value.on({ success: () => { fetchLogs(); } }, 'BaseTransportTimelineCard');

    onUnmounted(() => {
      modalEditTransport.value.unregisterObserver('BaseTransportTimelineCard');
    });

    return { events };
  },
  components: {
    WidgetCard,
    VsLoader,
    Timeline,
  },
};
</script>

<style lang="scss" scoped>
.base-transport-schedule-card {
  .transport-timeline-wrapper {
    overflow-y: auto;
    max-height: 400px;
  }

  &::v-deep {
    .timeline-wrapper {
      margin-top: 0;
    }
  }
}
</style>
