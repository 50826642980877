<template>
  <div class="base-cars-details">
    <b-container fluid>
      <b-row>
        <b-col cols="4">
          <base-transport-details-card
            :transport="transport"
            :isLoading="isLoading"
          />
        </b-col>
        <b-col cols="3">
          <base-transport-schedule-card
            :transport="transport"
            :isLoading="isLoading"
          />
        </b-col>
        <b-col cols="5">
          <base-transport-timeline-card
            :transport="transport"
            :isLoading="isLoading"
          />
        </b-col>
        <b-col cols="12">
          <base-transport-resources-card
            :transport="transport"
            :isLoading="isLoading"
          />
        </b-col>

        <b-col cols="6">
          <attachments-card v-if="files" :files="files" />
        </b-col>
      </b-row>
    </b-container>

    <base-transport-edit-modal @ok="refreshResource" />

    <orders-create-modal />
  </div>
</template>

<script>
import { BContainer, BRow, BCol } from 'bootstrap-vue';
import { computed, ref } from 'vue';
import useBaseApi from '@/hooks/base/useBaseApi';
import BaseTransportDetailsCard from '@/components/views/base/transport/widgets/BaseTransportDetailsCard.vue';
import BaseTransportScheduleCard from '@/components/views/base/transport/widgets/BaseTransportScheduleCard.vue';
import BaseTransportTimelineCard from '@/components/views/base/transport/widgets/BaseTransportTimelineCard.vue';
import BaseTransportResourcesCard from '@/components/views/base/transport/widgets/BaseTransportResourcesCard.vue';
import BaseTransportEditModal from '@/components/views/base/transport/modals/BaseTransportEditModal.vue';
import router from '@/router';
import showToast from '@/libs/toasts';
import { FetchTransportError } from '@/libs/toasts/feedback/module/base';
import OrdersCreateModal from '@/components/views/orders/modals/OrdersCreateModal.vue';
import { useRouter } from '@/@core/utils/utils';
import AttachmentsCard from '@/components/views/base/resource/widgets/AttachmentsCard.vue';

export default {
  name: 'CarsDetails',
  setup() {
    const { route } = useRouter();
    const transportId = computed(() => route.value.params.id);
    const transport = ref({ id: transportId.value });
    const isLoading = ref(false);

    const refreshResource = () => {
      isLoading.value = true;
      useBaseApi()
        .fetchTransport(transportId.value)
        .then(({ data }) => {
          transport.value = {
            ...data.data,
          };
        })
        .catch(() => {
          showToast(FetchTransportError);
          router.push({ name: 'base.transport' });
        })
        .finally(() => {
          isLoading.value = false;
        });
    };
    refreshResource();

    const files = computed(() => transport.value.files);

    return {
      transport, refreshResource, isLoading, files,
    };
  },
  components: {
    BContainer,
    BRow,
    BCol,
    BaseTransportDetailsCard,
    BaseTransportScheduleCard,
    BaseTransportTimelineCard,
    BaseTransportResourcesCard,
    BaseTransportEditModal,
    OrdersCreateModal,
    AttachmentsCard,
  },
};
</script>

<style lang="sass" scoped></style>
