<template>
  <b-card>

    <template #header v-if="$slots.header || $slots.title || title">
      <slot name="header">
        <h3>
          <slot name="title">
            {{ title }}
          </slot>
        </h3>
        <slot name="controls">
          <widget-card-controls
            v-if="withControls"
            :editPermission="editPermission"
            :deletePermission="deletePermission"
            @edit="$emit('edit')"
            @delete="$emit('delete')"
          />
        </slot>
      </slot>
    </template>

    <slot name="image" />
    <slot />

    <template #footer v-if="$slots.footer">
      <div class="widget-card-footer">
        <slot name="footer" />
      </div>
    </template>

  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import WidgetCardControls from './WidgetCardControls.vue';

export default {
  name: 'WidgetCard',
  emits: ['edit', 'delete'],
  props: {
    title: {
      type: String,
    },
    withControls: {
      type: Boolean,
      default: false,
    },
    editPermission: {
      type: String,
    },
    deletePermission: {
      type: String,
    },
  },
  components: {
    BCard,
    WidgetCardControls,
  },
};
</script>

<style lang="sass" scoped>
.card-footer
  border-top: none

.card
  height: calc(100% - 28px)
  overflow: visible
</style>
