var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-cars-details"
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('base-transport-details-card', {
    attrs: {
      "transport": _vm.transport,
      "isLoading": _vm.isLoading
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('base-transport-schedule-card', {
    attrs: {
      "transport": _vm.transport,
      "isLoading": _vm.isLoading
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('base-transport-timeline-card', {
    attrs: {
      "transport": _vm.transport,
      "isLoading": _vm.isLoading
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('base-transport-resources-card', {
    attrs: {
      "transport": _vm.transport,
      "isLoading": _vm.isLoading
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.files ? _c('attachments-card', {
    attrs: {
      "files": _vm.files
    }
  }) : _vm._e()], 1)], 1)], 1), _c('base-transport-edit-modal', {
    on: {
      "ok": _vm.refreshResource
    }
  }), _c('orders-create-modal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }