<template>
  <div class="details-table">
    <div class="details-table-row"
      v-for="(entry, idx) in nonEmptyFields"
      :key="`entry-${idx}`"
    >

      <div class="header">
        <slot :name="`header-${entry}`">
          {{ getHeaderName(entry) }}
        </slot>
      </div>

      <div class="value">
        <slot :name="`value-${entry}`">
          {{ entries[entry] }}
        </slot>
      </div>

    </div>
  </div>
</template>

<script>
import i18n from '@/libs/i18n';
import { computed } from 'vue';

export default {
  name: 'DetailsTable',
  props: {
    entries: {
      type: Object,
      default: () => ({}),
    },
    i18nGroup: {
      type: String,
    },
  },
  setup(props) {
    const getHeaderName = (header) => {
      if (!props.i18nGroup) return header;
      const translationKey = `${props.i18nGroup}.${header}`;
      return i18n.t(translationKey);
    };

    const nonEmptyFields = computed(() => Object
      .keys(props.entries)
      .filter((entry) => props.entries[entry] !== null));

    return { getHeaderName, nonEmptyFields };
  },
};
</script>

<style lang="sass" scoped>
.details-table
  .details-table-row
    display: grid
    grid-template-columns: minmax(auto, 150px) 1fr
    gap: 15px
    padding: 2px 0

    .header
      opacity: 0.6
      text-transform: lowercase
</style>
