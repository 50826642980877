<template>
  <widget-card
    class="base-transport-details-card"
    with-controls
    @edit="openEditModal"
    @delete="deleteTransport"
  >

    <template #title>
      {{ transport.name }}
      <base-transport-route-label
        :start="transport.from_investment"
        :destination="transport.to_investment"
        size="md"
      />
    </template>

    <vs-loader
      full
      text="Pobieranie danych zlecenia..."
      v-if="isLoading"
    />

    <details-table
      :entries="entries"
      i18n-group="base.transport.details.field"
    >
      <template #value-status>
        <vs-status-badge
          :data="$store.getters['base/transport/status/getStatusBySlug'](transport.status)"
        />
      </template>

      <template #value-company>
        <crm-company-label
          :integrated="transport.provider"
        />
      </template>

      <template #value-author>
        <user-label
          withAvatar
          :data="transport.author"
        />
      </template>

      <template #value-dispatcher>
        <user-label
          withAvatar
          :data="transport.release"
        />
      </template>

      <template #value-receiver>
        <user-label
          withAvatar
          :data="transport.receiver"
        />
      </template>

      <template #value-gps>
        <a
          :href="transport.gps_panel"
          class="gps-panel-link"
          target="_blank"
        >
          <feather-icon icon="ExternalLinkIcon" />
          Otwórz w nowej karcie
        </a>
      </template>

      <template #value-card>
        <div class="value-card">
          {{ entries.card }}

          <template v-if="pinVisible && transport.fuel_card_pin">
            <span
              @click="togglePinVisibility"
              class="fuel-card-pin-exposed"
            >
              {{ transport.fuel_card_pin }}
            </span>
          </template>
          <template v-else-if="transport.fuel_card_pin">
            <vs-button
              class="pin-button"
              vs-variant="light"
              @click="togglePinVisibility"
            >
              <feather-icon class="icon" icon="KeyIcon" />
              PIN
            </vs-button>
          </template>

        </div>
      </template>

    </details-table>

    <template #footer>

      <vs-button
        vs-variant="primary"
        @click="openOrderModal"
      >
        Utwórz zlecenie
      </vs-button>

      <vs-button
        vs-variant="light"
        @click="() => openEditModal({ status: 'onTheWay' })"
        v-if="transport.status === 'new'"
      >
        Wydanie
      </vs-button>

      <vs-button
        vs-variant="light"
        @click="() => openEditModal({ status: 'delivered' })"
        v-if="transport.status === 'onTheWay'"
      >
        Odbiór
      </vs-button>

    </template>

  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import DetailsTable from '@/components/ui/DetailsTable.vue';
import VsStatusBadge from '@/components/ui/vs-status-badge/VsStatusBadge.vue';
import { computed, ref } from 'vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import CrmCompanyLabel from '@/components/module/crm/CrmCompanyLabel.vue';
import BaseTransportRouteLabel from '@/components/module/base/BaseTransportRouteLabel.vue';
import UserLabel from '@/components/ui/UserLabel.vue';
import store from '@/store';
import useOrdersModals from '@/hooks/orders/useOrdersModals';
import formatDate from '@/libs/date-formatter';
import { useRouter } from '@/@core/utils/utils';
import i18n from '@/libs/i18n';
import { deleteModal } from '@/libs/modals';
import useBaseApi from '@/hooks/base/useBaseApi';
import showToast from '@/libs/toasts';
import { DeleteTransportError, DeleteTransportSuccess } from '@/libs/toasts/feedback/module/base';

export default {
  name: 'MachineDetailsCard',
  props: {
    transport: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    store.dispatch('branches/fetch');
    const transport = computed(() => props.transport ?? {});
    const branch = computed(() => {
      if (!transport?.value?.core_branch_id) return null;
      return store.getters['branches/byId'](transport.value.core_branch_id)?.shortcut;
    });

    const entries = computed(() => ({
      status: transport.value.status,
      company: transport.value.insurance_company?.company,
      type: transport.value.type?.name,
      branch: branch.value,
      author: transport.value.author,
      dispatcher: transport.value.release,
      receiver: transport.value.receiver,
    }));

    const title = computed(() => `${props.transport.name}, ${props.transport.register_number}`);

    const { modalEditTransport } = useBaseModals();
    const openEditModal = (additionalData = null) => {
      modalEditTransport.value.open({
        ...transport.value,
        ...additionalData ?? {},
        updateResourceStatuses: !!additionalData,
      });
    };

    const pinVisible = ref(false);
    const togglePinVisibility = () => {
      pinVisible.value = !pinVisible.value;
    };

    const { modalCreateOrder } = useOrdersModals();
    const openOrderModal = () => {
      modalCreateOrder.value.open({
        title: `Zlecenie transportu - ${transport.value.type.name}`,
        investment_id: transport.value.from_investment_id,
        realization_at: formatDate.standard(transport.value.transport_date),
        assigned_id: transport.value.author_id,
        accepting_id: transport.value.release_id,
        branch_id: transport.value.core_branch_id,
        delivery_place: transport.value.from_investment_id?.street,
      });
    };

    const { router } = useRouter();
    const deleteTransport = async () => {
      const confirmation = await deleteModal({
        title: i18n.t('base.transport.modal.delete.title'),
        text: i18n.t('base.transport.modal.delete.text', {
          name: transport.value?.name,
        }),
      });

      if (!confirmation) return;

      useBaseApi()
        .deleteTransport(transport.value.id)
        .then(() => {
          showToast(DeleteTransportSuccess, { name: transport.value.name });
          router.push({ name: 'base.transport' });
        })
        .catch(() => {
          showToast(DeleteTransportError);
        });
    };

    return {
      entries,
      title,
      openEditModal,
      pinVisible,
      togglePinVisibility,
      openOrderModal,
      deleteTransport,
    };
  },
  components: {
    WidgetCard,
    VsButton,
    DetailsTable,
    VsStatusBadge,
    VsLoader,
    CrmCompanyLabel,
    BaseTransportRouteLabel,
    UserLabel,
  },
};
</script>

<style lang="sass" scoped>
.base-transport-details-card
  .gps-panel-link
    display: flex
    align-items: center
    gap: 5px

  .fuel-card-pin-exposed
    background: var(--code-background)
    padding: 1px 5px
    border-radius: 5px

  &:deep(.widget-card-footer)
    display: flex
    align-items: center
    gap: 5px

  .vs-loader
    top: 0

  .value-card
    display: flex
    align-items: center
    gap: 10px

    .pin-button
      padding: 3px 8px
      font-size: 9pt

      .icon
        margin-right: 5px
</style>
