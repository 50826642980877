<template>
  <widget-card
    class="base-transport-schedule-card"
  >

    <template #title v-if="daysTillTransport >= 0">
      Transport {{ $tc('base.transport.details.field.transportIn', daysTillTransport) }}
    </template>

    <template #title v-else-if="daysTillDelivery >= 0">
      Dostawa {{ $tc('base.transport.details.field.transportIn', daysTillDelivery) }}
    </template>

    <template #title v-else>
      Transport zakończony
    </template>

    <vs-loader
      full
      text="Pobieranie danych zlecenia..."
      v-if="isLoading"
    />

    <details-table
      :entries="entries"
      i18n-group="base.transport.details.field"
    >
    </details-table>

  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import { computed } from 'vue';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import formatDate, { daysFromToday } from '@/libs/date-formatter';
import DetailsTable from '@/components/ui/DetailsTable.vue';

export default {
  name: 'MachineDetailsCard',
  props: {
    transport: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const title = computed(() => `${props.transport.name}, ${props.transport.register_number}`);

    const daysTillTransport = computed(() => {
      if (!props.transport?.transport_date) return 0;
      return daysFromToday(props.transport.transport_date, true);
    });

    const daysTillDelivery = computed(() => {
      if (!props.transport?.delivery_date) return 0;
      return daysFromToday(props.transport.delivery_date, true);
    });

    const entries = computed(() => ({
      transportDate: formatDate.transport(props.transport.transport_date),
      deliveryDate: formatDate.transport(props.transport.delivery_date),
    }));

    return {
      title,
      daysTillTransport,
      daysTillDelivery,
      entries,
    };
  },
  components: {
    WidgetCard,
    VsLoader,
    DetailsTable,
  },
};
</script>

<style lang="sass" scoped>
.base-transport-details-card
  .gps-panel-link
    display: flex
    align-items: center
    gap: 5px

  .fuel-card-pin-exposed
    background: var(--code-background)
    padding: 1px 5px
    border-radius: 5px

  .vs-loader
    top: 0

  .value-card
    display: flex
    align-items: center
    gap: 10px

    .pin-button
      padding: 3px 8px
      font-size: 9pt

      .icon
        margin-right: 5px
</style>
