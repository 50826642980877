var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', {
    staticClass: "base-transport-resources-card",
    attrs: {
      "title": "Lista maszyn i sprzętu"
    }
  }, [_vm.isLoading ? _c('vs-loader', {
    attrs: {
      "full": "",
      "text": "Pobieranie danych zlecenia..."
    }
  }) : _c('vs-table', {
    attrs: {
      "columns": _vm.resourceColumns,
      "rows": _vm.transport.resources,
      "showSearch": false,
      "showPagination": false
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref) {
        var column = _ref.column,
          row = _ref.row;
        return [column.field === 'name' ? _c('span', [_c('base-resource-label', {
          attrs: {
            "resource": row
          }
        })], 1) : column.field === 'type' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t(`base.global.resourceType.${row.type.slug}`)) + " ")]) : column.field === 'status' ? _c('span', [_c('vs-status-badge', {
          attrs: {
            "data": _vm.getStatusBySlug(row.pivot.status)
          }
        })], 1) : column.field === 'description' ? _c('span', [_vm._v(" " + _vm._s(row.pivot.description) + " ")]) : _vm._e()];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }