<template>
  <widget-card>
    <attachments
      useProps
      :items="attachments"
      :uploading="uploading"
      @add="addAttachment"
      @remove="removeAttachment"
    />
  </widget-card>
</template>

<script>
import { ref } from 'vue';
import WidgetCard from '@/components/ui/WidgetCard.vue';
import Attachments from '@/components/ui/attachments/Attachments.vue';
import ApiClient from '@/services/api';
// TODO: move these feedbacks
import {
  AttachmentCreateSuccess,
  AttachmentCreateError,
  AttachmentSizeError,
  AttachmentDeleteSuccess,
  AttachmentDeleteError,
} from '@feedback/module/tasks';
import { useRouter } from '@/@core/utils/utils';
import showToast from '@/libs/toasts';

export default {
  props: {
    files: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { route } = useRouter();
    const resourceId = route.value.params.id;
    const uploading = ref(false);
    const attachments = ref(props.files);

    const addAttachment = (file) => {
      if (!resourceId && resourceId !== 0) return;
      uploading.value = true;

      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('mediable_id', resourceId);

      ApiClient.post('api/base/resource/media', formData)
        .then(({ data }) => {
          showToast(AttachmentCreateSuccess);
          uploading.value = false;
          attachments.value.push(data.data?.file);
        })
        .catch(({ response }) => {
          if (response.status === 413 || response.status === 422) {
            showToast(AttachmentSizeError);
          } else {
            showToast(AttachmentCreateError);
          }
          uploading.value = false;
        });
    };

    const removeAttachment = async (id) => {
      ApiClient.delete(`api/base/resource/media/${id}`)
        .then(() => {
          showToast(AttachmentDeleteSuccess);
          attachments.value = attachments.value.filter((a) => a.id !== id);
        })
        .catch(() => showToast(AttachmentDeleteError));
    };

    return {
      uploading,
      addAttachment,
      attachments,
      removeAttachment,
    };
  },
  components: {
    WidgetCard,
    Attachments,
  },
};
</script>

<style lang="sass" scoped>
table
  width: 100%

  td
    padding: 10px 0

    .investment-status
      background: #79bdea
      color: darken(#79bdea, 40%)
      font-weight: 600
      border-radius: 3px
      padding: 3px 10px
      display: block
      text-align: center
      width: 100%

      &.done
        background: #d0dae0
        color: darken(#d0dae0, 40%)

.action-buttons
  position: absolute
  bottom: 15px

.action-button
  margin: 10px 0
  display: inline-block

  &:not(:last-child)
    margin-right: 10px
</style>
