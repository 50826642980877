var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    scopedSlots: _vm._u([_vm.$slots.header || _vm.$slots.title || _vm.title ? {
      key: "header",
      fn: function () {
        return [_vm._t("header", function () {
          return [_c('h3', [_vm._t("title", function () {
            return [_vm._v(" " + _vm._s(_vm.title) + " ")];
          })], 2), _vm._t("controls", function () {
            return [_vm.withControls ? _c('widget-card-controls', {
              attrs: {
                "editPermission": _vm.editPermission,
                "deletePermission": _vm.deletePermission
              },
              on: {
                "edit": function ($event) {
                  return _vm.$emit('edit');
                },
                "delete": function ($event) {
                  return _vm.$emit('delete');
                }
              }
            }) : _vm._e()];
          })];
        })];
      },
      proxy: true
    } : null, _vm.$slots.footer ? {
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "widget-card-footer"
        }, [_vm._t("footer")], 2)];
      },
      proxy: true
    } : null], null, true)
  }, [_vm._t("image"), _vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }