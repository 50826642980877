var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "details-table"
  }, _vm._l(_vm.nonEmptyFields, function (entry, idx) {
    return _c('div', {
      key: `entry-${idx}`,
      staticClass: "details-table-row"
    }, [_c('div', {
      staticClass: "header"
    }, [_vm._t(`header-${entry}`, function () {
      return [_vm._v(" " + _vm._s(_vm.getHeaderName(entry)) + " ")];
    })], 2), _c('div', {
      staticClass: "value"
    }, [_vm._t(`value-${entry}`, function () {
      return [_vm._v(" " + _vm._s(_vm.entries[entry]) + " ")];
    })], 2)]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }