<template>
  <widget-card
    title="Lista maszyn i sprzętu"
    class="base-transport-resources-card"
  >

    <vs-loader
      full
      text="Pobieranie danych zlecenia..."
      v-if="isLoading"
    />

    <vs-table v-else
      :columns="resourceColumns"
      :rows="transport.resources"
      :showSearch="false"
      :showPagination="false"
    >
      <template v-slot:row="{ column, row }" >

        <span v-if="column.field === 'name'">
          <base-resource-label :resource="row" />
        </span>

        <span v-else-if="column.field === 'type'">
          {{ $t(`base.global.resourceType.${row.type.slug}`) }}
        </span>

        <span v-else-if="column.field === 'status'">
          <vs-status-badge
            :data="getStatusBySlug(row.pivot.status)"
          />
        </span>

        <span v-else-if="column.field === 'description'">
          {{ row.pivot.description }}
        </span>

      </template>
    </vs-table>

  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import VsTable from '@/components/ui/vs-table/Table.vue';
import VsStatusBadge from '@/components/ui/vs-status-badge/VsStatusBadge.vue';
import store from '@/store';
import BaseResourceLabel from '../../resource/BaseResourceLabel.vue';

export default {
  name: 'MachineDetailsCard',
  props: {
    transport: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const resourceColumns = [
      { field: 'name', label: 'Zasób' },
      { field: 'serial_number', label: 'Nr ewidencyjny' },
      { field: 'type', label: 'Typ' },
      { field: 'status', label: 'Status' },
      { field: 'description', label: 'Uwagi' },
    ];

    const getStatusBySlug = (slug) => store
      .getters['base/transport/resourceStatus/getStatusBySlug'](slug);

    return { resourceColumns, getStatusBySlug };
  },
  components: {
    WidgetCard,
    VsLoader,
    VsTable,
    BaseResourceLabel,
    VsStatusBadge,
  },
};
</script>

<style lang="sass" scoped>
</style>
