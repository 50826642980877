var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', {
    staticClass: "base-transport-details-card",
    attrs: {
      "with-controls": ""
    },
    on: {
      "edit": _vm.openEditModal,
      "delete": _vm.deleteTransport
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.transport.name) + " "), _c('base-transport-route-label', {
          attrs: {
            "start": _vm.transport.from_investment,
            "destination": _vm.transport.to_investment,
            "size": "md"
          }
        })];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_c('vs-button', {
          attrs: {
            "vs-variant": "primary"
          },
          on: {
            "click": _vm.openOrderModal
          }
        }, [_vm._v(" Utwórz zlecenie ")]), _vm.transport.status === 'new' ? _c('vs-button', {
          attrs: {
            "vs-variant": "light"
          },
          on: {
            "click": function () {
              return _vm.openEditModal({
                status: 'onTheWay'
              });
            }
          }
        }, [_vm._v(" Wydanie ")]) : _vm._e(), _vm.transport.status === 'onTheWay' ? _c('vs-button', {
          attrs: {
            "vs-variant": "light"
          },
          on: {
            "click": function () {
              return _vm.openEditModal({
                status: 'delivered'
              });
            }
          }
        }, [_vm._v(" Odbiór ")]) : _vm._e()];
      },
      proxy: true
    }])
  }, [_vm.isLoading ? _c('vs-loader', {
    attrs: {
      "full": "",
      "text": "Pobieranie danych zlecenia..."
    }
  }) : _vm._e(), _c('details-table', {
    attrs: {
      "entries": _vm.entries,
      "i18n-group": "base.transport.details.field"
    },
    scopedSlots: _vm._u([{
      key: "value-status",
      fn: function () {
        return [_c('vs-status-badge', {
          attrs: {
            "data": _vm.$store.getters['base/transport/status/getStatusBySlug'](_vm.transport.status)
          }
        })];
      },
      proxy: true
    }, {
      key: "value-company",
      fn: function () {
        return [_c('crm-company-label', {
          attrs: {
            "integrated": _vm.transport.provider
          }
        })];
      },
      proxy: true
    }, {
      key: "value-author",
      fn: function () {
        return [_c('user-label', {
          attrs: {
            "withAvatar": "",
            "data": _vm.transport.author
          }
        })];
      },
      proxy: true
    }, {
      key: "value-dispatcher",
      fn: function () {
        return [_c('user-label', {
          attrs: {
            "withAvatar": "",
            "data": _vm.transport.release
          }
        })];
      },
      proxy: true
    }, {
      key: "value-receiver",
      fn: function () {
        return [_c('user-label', {
          attrs: {
            "withAvatar": "",
            "data": _vm.transport.receiver
          }
        })];
      },
      proxy: true
    }, {
      key: "value-gps",
      fn: function () {
        return [_c('a', {
          staticClass: "gps-panel-link",
          attrs: {
            "href": _vm.transport.gps_panel,
            "target": "_blank"
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ExternalLinkIcon"
          }
        }), _vm._v(" Otwórz w nowej karcie ")], 1)];
      },
      proxy: true
    }, {
      key: "value-card",
      fn: function () {
        return [_c('div', {
          staticClass: "value-card"
        }, [_vm._v(" " + _vm._s(_vm.entries.card) + " "), _vm.pinVisible && _vm.transport.fuel_card_pin ? [_c('span', {
          staticClass: "fuel-card-pin-exposed",
          on: {
            "click": _vm.togglePinVisibility
          }
        }, [_vm._v(" " + _vm._s(_vm.transport.fuel_card_pin) + " ")])] : _vm.transport.fuel_card_pin ? [_c('vs-button', {
          staticClass: "pin-button",
          attrs: {
            "vs-variant": "light"
          },
          on: {
            "click": _vm.togglePinVisibility
          }
        }, [_c('feather-icon', {
          staticClass: "icon",
          attrs: {
            "icon": "KeyIcon"
          }
        }), _vm._v(" PIN ")], 1)] : _vm._e()], 2)];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }