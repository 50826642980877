var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', {
    staticClass: "base-transport-schedule-card",
    attrs: {
      "title": "Oś czasu"
    }
  }, [_c('div', {
    staticClass: "transport-timeline-wrapper"
  }, [_c('timeline', {
    attrs: {
      "items": _vm.events,
      "hideHeader": ""
    }
  })], 1), _vm.isLoading ? _c('vs-loader', {
    attrs: {
      "full": "",
      "text": "Pobieranie danych zlecenia..."
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }